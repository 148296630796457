import React from 'react'
import styled from 'styled-components'
import './SplitScreen.css'


const FullWidthColorSectionComponent = styled.div`
	background: ${ props => {
		console.log(props)
		switch (props.color) {
			case 'green':
				return 'var(--color__gray)'
			case 'blue':
				return 'var(--color__blue)'
			case 'gray':
				return 'var(--color__gray)'
			case 'white':
				return 'transparent'
			default:
				return 'var(--color__gray)'
		}
	}};
	padding: 80px;
	color: ${ props => {
		console.log(props)
		switch (props.color) {
			case 'green':
				return 'var(--color__on_gray)'
			case 'blue':
				return 'var(--color__on_blue)'
			case 'gray':
				return 'var(--color__on_gray)'
			case 'white':
				return 'black'
			default:
				return 'var(--color__on_gray)'
		}
	}};
	position: relative;
	overflow: hidden;

	> div {
		max-width: 1200px;
		margin-left: auto;
		margin-right: auto;
		z-index: 2;
		position: relative;
	}

	@media (max-width: 1200px) {
		padding: 40px;
	}

`;

const FullWidthColorSection = ({children, ...props}) => <FullWidthColorSectionComponent {...props}><div>{children}</div><span className="dot"></span></FullWidthColorSectionComponent>

const ColorSection = ({color, children}) => {
	return (
		<FullWidthColorSection color={color}>
			{ children }
		</FullWidthColorSection>
	)
}

export default ColorSection
