import React from 'react'
import ColorSection from '../ColorSection'
import WorkGrid, { WorkArticle } from '../WorkGrid'

import dankstellenFinderImage from './vielmehrheidelberg-dankstellen-finder.jpg'
import camelotBlogImage from './camelot-blog.jpg'
import hitsBlogImage from './hits-blog.jpg'
import yvonneBlogImage from './yvonne-blog.jpg'
import { WorkBanner } from '../WorkBanner'
import ctaDigitalHabitat from './cta-digital-habitat.jpg'
import hitsContentBlocker from './hits-content-blocker.jpg'
import { CopyTextWrapper } from '../CopyTextWrapper'

const UI = () => {
	return (
		<ColorSection color="gray">
			<CopyTextWrapper>
				<h2 className="text-center"> Tools & Prozesse</h2>
				{/* <h3 className="text-center">Beratungsstellen Finder</h3>
				<p>
					Beratungsstellen können im Backend (als Datensätze) über Eingabefelder manuell angelegt werden. Es werden Name der Beratungsstelle, optional eine kurze Beschreibung, die  Adresse, Kontaktdaten und Website hinterlegt.
				</p>
				<p>
					Auf der Website erfolgt die Ansicht als Liste oder auf einer interaktive Karte. Über Filter wie z.B Kategorien oder Umkreissuche können die Beratungsstellen selektiert werden. Die Filter lassen sich einfach im Backend jederzeit anpassen oder ergänzen.
				</p> */}
			</CopyTextWrapper>
			<div style={{height: 16}}></div>
			<WorkBanner image={dankstellenFinderImage} href="https://www.vielmehr.heidelberg.de/dankstelle/">
				<h4>
					Unternehmens Übersicht für die online Plattform viel mehr Heidelberg 
				</h4>
				<p>
					Unternehmen können in einer Listen und Kartenansicht dargestellt und auf Basis verschiedener Eigenschaften gefiltert werden. Die Filterauswahl bleibt beim Welchsel zwischen Listen und Kartenansicht bestehen.
				</p>
				<p>
					Hinweis: Eine solche Kartenansicht die auch für MUP gewünscht ist, ist nicht zu 100% barrierefrei umsetzbar, daher ist diese erst nach klick auf "Kartenansicht" sichtbar.
				</p>
			</WorkBanner>
			<div style={{height:64}}></div>
			<CopyTextWrapper>
				<h3 className="text-center">Blog System</h3>
				<p>
					Dort können News  oder Artikel als Blog-Posts veröffentlicht werden, die dann auch über eine Such- und Filter-funktionalität selektiert werden können. Üblicherweise werden zunächst die Beiträge auf der Website im Blog veröffentlicht und können dann in unregelmäßigen Abständen in einem Newsletter-Versand auf die unterschiedlichen Beiträge versendet werden.
				</p>
			</CopyTextWrapper>
			<div style={{height:16}}></div>

			<WorkGrid>
				<WorkArticle href="https://blog.camelot-group.com/de">
					<img src={camelotBlogImage} alt="camelot blog login" loading="lazy" />
					<h4>Unternehmensblog der Camelot&nbsp;Group</h4>
				</WorkArticle>
				<WorkArticle href="https://www.camelot-mc.com/capabilities/data-management-analytics/community-for-data-driven-leaders/intelligent-data-management-transformation/">
					<img src={hitsBlogImage} alt="camelot community" loading="lazy" />
					<h4>
						News Blog des Heidelberger Institut für Theoretische Studien
					</h4>
				</WorkArticle>
				<WorkArticle href="https://www.yvonnewillicks.de/blog/">
					<img src={yvonneBlogImage} alt="Digital Habitat" loading="lazy" />
					<h4>Blog der Fernsehmoderatorin und Buchautorin Yvonne&nbsp;Willicks</h4>
				</WorkArticle>
			</WorkGrid>

			<div style={{height:64}}></div>
			<CopyTextWrapper>
				<h3 className="text-center">Call to Action / Kontaktformular</h3>
				<p>
					Über das Backend fügen wir ein Modul hinzu, mit dem sich selbstständig beliebig viele Call-to-Actions auf der Seite einfach integrieren lassen. Als Call-to-Actions bezeichnen wir  z. B. die Anmeldung zum Newsletter, das Aufrufen eines Kontaktformulars oder der Download von Premium Inhalten. Wir integrieren die Funktion, dass beim Absenden (z. B. des Kontaktformulars) durch einen Call-to-Action eine automatische Email an den Nutzer geschickt wird. Diese Email beinhaltet je nach Call-to-Action weitere Schritte, wie oben bereits ausgeführt.
					Über das Backend können Kontaktformulare selbstständig erstellt und in beliebige Seiten eingefügt werden
				</p>
			</CopyTextWrapper>
			<WorkGrid>
				<WorkArticle href="https://digital-habitat.io/#kontakt">
					<img src={ctaDigitalHabitat} alt="Camelot Events" loading="lazy" />
					<h4>Kontaktformular für Digital Habitat</h4>
				</WorkArticle>
			</WorkGrid>
			<div style={{height:64}}></div>

			<CopyTextWrapper>
				<h3 className="text-center">Youtube Integration mit Opt-In Lösung</h3>
				<p>
					Das DSGVO-konforme Einbetten von Youtube oder Vimeo Videos ist nur über ein 2-Klick-Verfahren möglich. Dazu wird ein Video-Link im Backend hinterlegt, das Video ist als graue Fläche auf der Webseite sichtbar, der Nutzer muss aber zuvor über einen Button das Abspielen erneut bestätigen. 
				</p>
			</CopyTextWrapper>

			<WorkBanner image={hitsContentBlocker} href="https://www.h-its.org/career/our-alumni/">
				<h4>Implementierung einer Video Galerie mit Content-Blocker Funktion</h4>
				<p>
					Videos werden von Youtube eingebunden. Da dies datenschutzurechtlich nicht erlaubt ist, werden die Video Einbettungen automatisch durch ein Content-Blocker Blocker Funktion blockert. Erst nach klick auf "Video laden" durch die Nutzer werden die Videos geladen. Dabei merkt sich die Website die Einstellung der Nutzer sodass diese nicht mehrfach eine Einwilligung geben müssen.
				</p>
				<p>
					Da diese datenschutzrechtlichen Vorgaben nicht nur für Youtube sondern für alle US-Dienste gelten, funktioniert diese Content Blocker Funktion unter anderem auch für Einbettungen von Twitter-Tweets, Vimeo-Videos, Facebook Posts, etc.<br />
					Eine Unterstützung für weitere Dienste kann implementiert werden.
				</p>
			</WorkBanner>


			<div style={{height:64}}></div>
			<CopyTextWrapper>
				<h3 className="text-center">Integration Matomo</h3>
				<p>
					Wir setzen für die Auswertung von Nutzerstatistiken das Analyse-Tool “Matomo” ein. Matomo haben auch wir bereits für die Website wosonst.eu der  Metropolregion Rhein-Neckar implementiert und aufgesetzt.
				</p>
				<p>
					Matomo ist DSGVO-konform einsetzbar und bietet einen nahezu identischen Funktionsumfang wie Google Analytics. Teil unseres Angebotes ist die Integration und Einbindung der Basisfunktionalität von Matomo, das beinhaltet alle wichtigen Statistiken wie z. B. Nutzerzahlen, Aufrufe von Seiten, Akquisition von Nutzern (woher sind die Nutzer auf die Website gekommen), Verweil- und Nutzungsdauer, Aufrufe von bestimmten Inhalten, etc.
				</p>
			</CopyTextWrapper>

			{/* <WorkBanner image={hitsContentBlocker} href="">
				<p>
					Die Integration des Matomo Nutzertrackings wurde auf folgenden Seiten eingesetzt. Matomo wird hier in der „Cookie-freien“ Version geladen, dadurch ist entsprechend der DGSVO keine Nutzerzustimmung notwendig.
				</p>
			</WorkBanner> */}
		</ColorSection>
	)
}

export default UI
