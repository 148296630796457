import React from 'react'
import styled from 'styled-components'

const FooterComponent = styled.div`
	text-align: center;
	padding: 40px 40px 52px;
	/* background-color: var(--color__gray); */

	h2 {
		margin-bottom: 32px;
		/* font-weight: normal; */
		font-size: 24px;
	}

	.logo {
		max-width: 200px;
	}
`;

const Footer = () => {
	return (
		<FooterComponent>
			<h2>Erstellt von</h2>
			<a href="https://www.werbeagenten.de" target="_blank" rel="noreferrer" >
				<img src="/werbeagenten-logo.svg" alt="Werbeagenten Logo" className="logo" loading="lazy" />
			</a>
		</FooterComponent>
	)
}

export default Footer;
