import React from 'react'
import styled from 'styled-components'
import './SplitScreen.css'


const WorkBannerWrapper = styled.article`
	margin-top: 32px;
	
	> a {
		display: flex;
		gap: 32px;
		align-items: center;

		> * {
			width: calc( 50% - 16px);
			min-width: 200px;
			flex-grow: 1;
			flex-shrink: 0;
		}
	}


	@media (max-width: 1200px) {
		> a {
			flex-direction: column-reverse;

			> * {
				width: 100%;
			}
		}
	}


	img {
		aspect-ratio: 2 / 1;
		object-fit: cover;
		object-position: top;
		/* width: calc(50vw - 64px); */
		border-radius: 0px;
		/* box-shadow: 0 10px 20px rgba(0,0,0,0.2) */
		/* border: 8px solid var(--color__gray); */
	}

	a {
		color: currentColor;
		text-decoration: none;
	}

	a:hover h4 {
		text-decoration: underline;
		text-underline-offset: 2px;
		text-decoration-thickness: 2px;
	}
`;


export const WorkBanner = ({children, href, image}) => (
	<WorkBannerWrapper>
		<a href={href} target="_blank" rel="noreferrer">
			<div>
				{children}
			</div>

			<img src={image} alt="" loading="lazy"/>;
		</a>
	</WorkBannerWrapper>
)
