import React from 'react'
import ColorSection from '../ColorSection'
import WorkGrid, { WorkArticle } from '../WorkGrid'

// import vielmehrLoginImage from './vielmehr-login.jpg'
// import camelotLoginImage from './camelot-login.jpg'
import camelotEventsImage from './camelot-event.jpg'
import hitsEventImage from './h-its-events.jpg'
import sanktElisabethEvents from './sankt-elisabeth-events.jpg'
import { CopyTextWrapper } from '../CopyTextWrapper'

const UI = () => {
	return (
		<ColorSection color="blue">
			{/* <CopyTextWrapper>
				<h2 className="text-center">Interner Bereich für externe Nutzer</h2>
				<h3 className="text-center">Referenz Websites zu Login</h3>
				<p>
					Erstellung von Unternehmens-Login erfolgt im ersten Schritt manuell durch MUP-Mitarbeiter nachdem ein Unternehmen eine Mitgliedschaft abgeschlossen hat. Ein Unternehmens-Account kann mehrfach durch unterschiedliche Nutzer und gleichzeitig verwendet werden. Die zentrale Benutzerverwaltung erfolgt über das Wordpress Backend und kann durch MUP jederzeit selbstständig verwaltet werden. 
				</p>
			</CopyTextWrapper>
			<div style={{height: 16}}></div>
			<WorkGrid>
				<WorkArticle href="https://vielmehr.heidelberg.de">
					<img src={vielmehrLoginImage} alt="vielmehr heidelberg login" loading="lazy" />
					<h4>Online Plattform für Unternehmen in Heidelberg.</h4>
					<p>
						Login Bereich für Unternehmen für die Bearbeitung ihrer Unternehmensprofile auf der Platform
					</p>
				</WorkArticle>
				<WorkArticle href="https://www.camelot-mc.com/capabilities/data-management-analytics/community-for-data-driven-leaders/intelligent-data-management-transformation/">
					<img src={camelotLoginImage} alt="camelot community" loading="lazy" />
					<h4>
						Online Community
					</h4>
					<p>
						Interner Bereich für eine online Community.
					</p>
				</WorkArticle>
			</WorkGrid>
			<div style={{height: 64}}></div> */}

			<CopyTextWrapper>
				<h2 className="text-center">Referenz Websites zu Veranstaltungen</h2>
				<p>
				Im Adminbereich (Backend) können für Kunden Events angelegt und im Login-Bereich beworben werden. Diese Events beinhalten ein Bild, einen Namen, Beschreibung, Datum und Uhrzeit, Ort oder Link zum Event. Außerdem können diese Events als digitaler Kalendereintrag heruntergeladen und im persönlichen Kalender gespeichert werden.
				</p>
			</CopyTextWrapper>
			<div style={{height: 16}}></div>
			<WorkGrid>
				<WorkArticle href="https://www.camelot-itlab.com/de/events/">
					<img src={camelotEventsImage} alt="Camelot Events" loading="lazy" />
					<h4>Event Übersicht für Camelot Group</h4>
				</WorkArticle>
				<WorkArticle href="https://www.h-its.org/events/">
					<img src={hitsEventImage} alt="HITS Events" loading="lazy" />
					<h4>Event übersicht für das Heidelberger Institut für Theoretische Studien</h4>
				</WorkArticle>
				<WorkArticle href="https://www.sankt-elisabeth.de/veranstaltungen/">
					<img src={sanktElisabethEvents} alt="Klinik Sankt Elisabeth Kurse" loading="lazy" />
					<h4>Kursübersicht für die Klinik Sankt Elisabeth Heidelberg</h4>
				</WorkArticle>
			</WorkGrid>

		</ColorSection>
	)
}

export default UI
