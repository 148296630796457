import styled from 'styled-components'

export const CopyTextWrapper = styled.div`
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 32px;

	p {
		font-size: 20px;
	}
`