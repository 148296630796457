import React from 'react'
import ColorSection from '../ColorSection'
import { CopyTextWrapper } from '../CopyTextWrapper'
import WorkGrid, { WorkArticle } from '../WorkGrid'

import hekaImage from './heka.jpg'
import wenzelImage from './wenzel.jpg'

const Texte = () => {
	return (
		<ColorSection color="blue">
			<CopyTextWrapper>
				<h2 className="text-center">SEO Text Support</h2>
				<h3 className="text-center">Referenz zu Suchmaschinenoptimierung und Texterstellung</h3>
			</CopyTextWrapper>
			{/* <CopyTextWrapper>
				<p>
					Erstellung von Unternehmens-Login erfolgt im ersten Schritt manuell durch MUP-Mitarbeiter nachdem ein Unternehmen eine Mitgliedschaft abgeschlossen hat. Ein Unternehmens-Account kann mehrfach durch unterschiedliche Nutzer und gleichzeitig verwendet werden. Die zentrale Benutzerverwaltung erfolgt über das Wordpress Backend und kann durch MUP jederzeit selbstständig verwaltet werden. 
				</p>
			</CopyTextWrapper> */}
			<div style={{height: 16}}></div>
			<WorkGrid>
				<WorkArticle href="https://www.hekaenergy.de/">
					<img src={hekaImage} alt="hekaenergy" loading="lazy" />
					<h4>Textüberarbeitung und Suchmaschinenoptimierung für HEKA Energy</h4>
					<p>
						Optimierung von kundenseitig gelieferten Texten. SEO Review und Optimierung. Erstellung von Landingpages.
					</p>
				</WorkArticle>
				<WorkArticle href="https://www.elektrowenzel.de/">
					<img src={wenzelImage} alt="wenzel elektro" loading="lazy" />
					<h4>
						Texterstellung für Wenzel Elektro
					</h4>
					<p>
						Texterstellung und Suchmaschinenoptimierung.
					</p>
				</WorkArticle>
			</WorkGrid>
			<div style={{height: 64}}></div>
		</ColorSection>
	)
}

export default Texte
