import React from 'react'
import styled from 'styled-components'
import './SplitScreen.css'


const WorkGrid = styled.div`
	display: flex;
	/* grid-template-columns: repeat(3, 1fr); */
	gap: 32px;
	flex-wrap: wrap;
	margin-top: 32px;

	> * {
		width: calc( 25% - 24px);
		/* width: 100%; */
		min-width: 200px;
		flex-grow: 1;
		flex-shrink: 0;
	}

	@media (max-width: 1200px) {
		> * {
			width: 100%;

		}
	}

	h4 {
		margin-top: 8px;
		margin-bottom: 0;
	}

	img {
		aspect-ratio: 2 / 1;
		object-fit: cover;
		object-position: top;
		width: 100%
	}

	a {
		color: currentColor;
		text-decoration: none;
	}

	a:hover h4 {
		text-decoration: underline;
		text-underline-offset: 2px;
		text-decoration-thickness: 2px;
	}
`;

export default WorkGrid

export const WorkArticle = ({children, href}) => (
	<article>
		<a href={href} target="_blank" rel="noreferrer">
			{children}
		</a>
	</article>
)
