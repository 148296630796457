import React from 'react'
import ColorSection from '../ColorSection'
import { CopyTextWrapper } from '../CopyTextWrapper'
import WorkGrid, { WorkArticle } from '../WorkGrid'

import hitsGroup from './hits-group.jpg'
import hitsFrontImage from './hits-frontpage.jpg'
import hitsPublications from './hits-publications.jpg'

const MainCase = () => {
	return (
		<ColorSection color="blue">
			<CopyTextWrapper>
				<h2 className="text-center">HITS Website</h2>
				<h3 className="text-center">Referenz Website Relaunch und Corporate Design Facelift für das Heidelberger Institut für Theoretische Studien (HITS)</h3>
			</CopyTextWrapper>
			{/* <CopyTextWrapper>
				<p>
					Erstellung von Unternehmens-Login erfolgt im ersten Schritt manuell durch MUP-Mitarbeiter nachdem ein Unternehmen eine Mitgliedschaft abgeschlossen hat. Ein Unternehmens-Account kann mehrfach durch unterschiedliche Nutzer und gleichzeitig verwendet werden. Die zentrale Benutzerverwaltung erfolgt über das Wordpress Backend und kann durch MUP jederzeit selbstständig verwaltet werden. 
				</p>
			</CopyTextWrapper> */}
			<div style={{height: 16}}></div>
			<WorkGrid>
				<WorkArticle href="https://www.h-its.org/">
					<img src={hitsFrontImage} alt="hekaenergy" loading="lazy" />
					<h4>Redesign HITS Website</h4>
					<p>
						Überführung Corporate Design Relaunch in neues Webdesign Konzept inklusive Bildkonzept, Seitenstruktur und technische Beratung.
					</p>
				</WorkArticle>
				<WorkArticle href="https://www.hekaenergy.de/">
					<img src={hitsGroup} alt="hekaenergy" loading="lazy" />
					<h4>
						Entwicklungs eines Berechtigungskonzepts
					</h4>
					<p>
						Unterschiedliche Formungsgruppen haben in diese konkreten Fall ein komplexes Konzept für Nutzer und deren Berechtigungen erfordert.
					</p>
				</WorkArticle>
				<WorkArticle href="https://www.elektrowenzel.de/">
					<img src={hitsPublications} alt="wenzel elektro" loading="lazy" />
					<h4>
						Integration externer Datenbank für Wissenschaftliche Publikationen
					</h4>
					<p>
						Anbindung einer Wissenschaftlichen Publikationsdatenbank durch Schnittstellenenwicklung.
					</p>
				</WorkArticle>
			</WorkGrid>
			<div style={{height: 64}}></div>
		</ColorSection>
	)
}

export default MainCase
