import React from 'react'
import ColorSection from '../ColorSection'
import WorkGrid, { WorkArticle } from '../WorkGrid'

import opusImage from './opus.jpg'
import camelotImage from './camelot.jpg'
import digitalHabitatImage from './digital-habitat.jpg'

import wfCarreer from './wf-carreer.png'
import wfFrontPage from './wf-front-page.png'
import wfUnterseite from './wf-unterseite.png'
import styled from 'styled-components'
import { CopyTextWrapper } from '../CopyTextWrapper'
import { SRLWrapper } from "simple-react-lightbox";
import vielmehrLoginImage from './vielmehr-login.jpg'

const WireframesGrid = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 32px;

	img {
		width: 100%;
		/* max-height: 300px; */
		aspect-ratio: 1 / 1.5;
		object-fit: cover;
		object-position: top;
	}
`


const WireFrames = () => {
	return (
		<SRLWrapper>
			<WireframesGrid>
					<img src={wfCarreer} loading="lazy" srl_gallery_image="true" alt="wireframes 1" />
					<img src={wfFrontPage}  loading="lazy" srl_gallery_image="true" alt="wireframes 2" />
					<img src={wfUnterseite}  loading="lazy" srl_gallery_image="true" alt="wireframes 3" />
			</WireframesGrid>
		</SRLWrapper>
	)
}

const UI = () => {
	return (
		<ColorSection>
			<CopyTextWrapper>
				<h2 className="text-center">USER EXPERIENCE & WEBDESIGN</h2>
				<h3 className="text-center">Beispiele zu Wireframes</h3>
				<p>
				Im ersten Schritt planen wir den Aufbau aller Seiten und Unterseiten wir mithilfe einer Wireframes-Darstellung (Drahtgitter-Darstellung), also einer schematischen Ansicht aller auf einer Seite befindlichen Komponenten und Inhalte. Diese Darstellung beinhaltet im ersten Schritt noch kein Design und lässt es zu, eine präzise Nutzerführung durch die Website zu konzipieren.  
				</p>
			</CopyTextWrapper>

			<div style={{height: 16}}></div>
			
			<WireFrames />

			<div style={{height: 64}}></div>

			<CopyTextWrapper>
				<h3 className="text-center">Referenz Websites zu UX/UI DESIGN</h3>
				<p>
					Auf Basis der Wireframes wird im weiteren Projektabschnitt das Webdesign von uns entwickelt. Um eine klare Vorstellung der späteren Website zu ermöglichen, bereiten wir die finalen Designs als Clickdummy auf – also als klickbare Website auf Basis von miteinander verlinkten Bildern.
				</p>
			</CopyTextWrapper>
			<div style={{height: 16}}></div>
			<WorkGrid>
				<WorkArticle href="https://www.camelot-itlab.com/">
					<img src={camelotImage} alt="camelot" loading="lazy" />
					<h4>Corporate Website für Camelot Group</h4>
				</WorkArticle>
				<WorkArticle href="https://www.restaurant-opus-v.de/">
					<img src={opusImage} alt="Opus" loading="lazy" />
					<h4>Website für Sternerestaurant Opus V in Mannheim</h4>
				</WorkArticle>
				<WorkArticle href="https://digital-habitat.io/">
					<img src={digitalHabitatImage} alt="Digital Habitat" loading="lazy" />
					<h4>Corporate Website für Digital Habitat</h4>
				</WorkArticle>
				<WorkArticle href="https://digital-habitat.io/">
					<img src={vielmehrLoginImage} alt="Digital Habitat" loading="lazy" />
					<h4>Corporate Website für Digital Habitat</h4>
				</WorkArticle>
			</WorkGrid>
		</ColorSection>
	)
}

export default UI
