import React from 'react'
import './SplitScreen.css'

const SplitScreen = ({image, children}) => {
	return (
		<div className="SplitScreen">
			<div>
				{children}
			</div>

			<img src={image} alt="" loading="lazy"/>			
		</div>
	)
}

export default SplitScreen
